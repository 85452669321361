/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jestjs.io/docs/getting-started"
  }, "Jestjs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mochajs.org/"
  }, "Mochajs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jasmine.github.io/"
  }, "jasmin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://karma-runner.github.io/3.0/"
  }, "Karma")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.browserstack.com/automate/cypress"
  }, "Cypress")), "\n"), "\n", React.createElement(_components.h2, null, "Jest"), "\n", React.createElement(_components.h3, null, "Example Single Test"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=\"foo.js\""
  }, "export function foo(a,b){\n\treturn a + b\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js:title=\"foo.test.js\""
  }, "import foo from \"./foo.js\"\n\ntest('test foo', async () => {\n\texpect(foo(3, 6)).toBe(9);\n});\n")), "\n", React.createElement(_components.h3, null, "Test Specific Path"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jest path/to/my-test.js\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "npm test -- path/to/my-test.js\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
